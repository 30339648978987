const SpinningSpan = ({ className }: { className?: string }) => (
  <span
    className={`animate-spin inline-block w-6 h-6 border-4 border-current border-t-transparent
                  rounded-full text-secondary ${className}`}
    role="status"
    aria-label="loading"
  ></span>
)

export default SpinningSpan
