// app/[config]/login/page.tsx

"use client"
import { useState } from "react"
import { useRouter } from "next/navigation"

import backgroundImage from "@/public/images/HAVITAT.004.png"
import LandingHeader from "@/app/components/landingHeader"
import SpinningSpan from "@/app/components/spinningSpan"
import { type User, type ConfigProp } from "@/app/types"

const loginUser = async (email: string, password: string) => {
  const response = await fetch("/api/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  })
  console.log("response", response)
  if (!response.ok) {
    throw response
  }

  return response.json()
}

const LoginPage: React.FC<ConfigProp> = ({ params }) => {
  const lang = params.config

  const [email, set_email] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const router = useRouter()

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault()
    setLoading(true)
    setError(null)

    try {
      const data: { user: User } = await loginUser(email, password)
      const firstComplexId = data.user.related_complexes[0].id
      router.push(`/${lang}/${firstComplexId}/home`)
    } catch (response: any) {
      const errorMessage = await handleLoginError(response)
      setError(errorMessage)
      setLoading(false)
    }
  }

  const handleLoginError = async (response: Response) => {
    if (response.status === 401) {
      return lang === "en"
        ? "Incorrect credentials"
        : "Credenciales incorrectas"
    }
    const errorData = await response.json()
    return (
      errorData.message ||
      (lang === "en" ? "Login failed" : "Inicio de sesión fallido")
    )
  }

  return (
    <div className="relative -z-0 min-h-screen overflow-hidden">
      <div
        style={{ backgroundImage: `url(${backgroundImage.src})` }}
        className="absolute -z-10 h-full w-full bg-cover bg-center brightness-75 font-light top-0 left-0"
      ></div>
      <LandingHeader lang={lang} />
      <div className="  sm:flex sm:justify-end sm:pr-10 md:pr-24 lg:pr-32">
        <div className=" mx-auto sm:mx-0 max-w-sm md:max-w-lg">
          <p className="mx-4 sm:mx-0 text-white text-2xl sm:text-3xl md:text-4xl text-center sm:text-right font-light">
            {lang === "en"
              ? "Create your account and discover an easier way to manage your property."
              : "Crea tu cuenta y conoce una forma mas fácil de administrar tu propiedad."}
          </p>
          <div className="mt-4 flex justify-center sm:justify-end">
            <form
              onSubmit={handleLogin}
              className="w-72 mt-8 flex flex-col gap-2 text-primary-content"
            >
              <input
                type="text"
                value={email}
                placeholder="Email"
                onChange={(e) => set_email(e.target.value)}
                required
                className="input input-bordered h-10"
              />
              <input
                type="password"
                value={password}
                placeholder={lang === "en" ? "Password" : "Contraseña"}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="input input-bordered h-10"
              />
              {error && <p className="text-red-500">{error}</p>}
              <button
                test-id="submit"
                className="btn btn-primary btn-sm h-10 text-white capitalize tracking-wide"
                type="submit"
              >
                {loading ? (
                  <SpinningSpan className="text-white" />
                ) : lang === "en" ? (
                  "login"
                ) : (
                  "ingresar"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
