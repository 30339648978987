// app/components/landingHeader.tsx

"use client"

import Link from "next/link"
import Image from "next/image"
import { usePathname } from "next/navigation"

const LandingHeader = ({ lang }: { lang: "en" | "es" }) => {
  const links = [
    { href: "/login", label: lang === "en" ? "login" : "iniciar sesión" },
  ]
  const pathname = usePathname()

  return (
    <div className="h-24 flex justify-between md:pl-12 pr-5 md:pr-16 items-center mb-24 ">
      <Image
        src="/images/HAVITAT-WHITE.png"
        alt="Havitat Logo"
        className="w-64 sm:w-80 h-auto brightness-200"
        width={400}
        height={100}
        priority
      />
      <div className="flex text-white  items-center gap-6">
        {links.map((link: any, i: number) => {
          if (pathname.includes(link.href)) return null

          return (
            <Link key={i} href={`/${lang}${link.href}`}>
              <button className="btn btn-ghost uppercase text-xs sm:text-lg font-normal">
                {link.label}
              </button>
            </Link>
          )
        })}

        {pathname.includes("/demo") ? null : (
          <Link href={`/${lang}/demo`}>
            <button className="btn btn-ghost sm:btn-primary sm:bg-primary btn-sm w-min sm:w-auto sm:px-4 sm:h-9 text-white sm:text-white text-xs sm:text-base tracking-wide uppercase sm:capitalize ml-4">
              {lang === "en" ? "request a demo" : "solicite un demo"}
            </button>
          </Link>
        )}
      </div>
    </div>
  )
}

export default LandingHeader
